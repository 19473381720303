"use client";

import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { useTranslation } from "react-i18next";
import { NotifyButton } from "./NotifyMeButton";
export function LandingIPhone16() {
  return <>
      <ProductOne data-sentry-element="ProductOne" data-sentry-source-file="LandingIPhone16.tsx" />
      <ProductTwo data-sentry-element="ProductTwo" data-sentry-source-file="LandingIPhone16.tsx" />
      <div>
        <Image alt="iPhone 16" height={500} src="/img/products/Landingpage_offer_iPhone_16-01.jpg" width={1500} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
        <Link className="block" href="https://trade-in.uficon.com" target="_blank" data-sentry-element="Link" data-sentry-source-file="LandingIPhone16.tsx">
          <Image alt="iPhone 16" height={500} src="/img/products/Landingpage_offer_iPhone_16-02.jpg" width={1500} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
        </Link>
        <Image alt="iPhone 16" height={500} src="/img/products/Landingpage_offer_iPhone_16-04.jpg" width={1500} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
        <Image alt="iPhone 16" height={500} src="/img/products/Landingpage_offer_iPhone_16-05.jpg" width={1500} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
        <Link className="block" href="https://www.uficon.com/bankpromotion-onlin" target="_blank" data-sentry-element="Link" data-sentry-source-file="LandingIPhone16.tsx">
          <Image alt="iPhone 16" height={500} src="/img/products/Landingpage_offer_iPhone_16-03.jpg" width={1500} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
        </Link>
      </div>
    </>;
}
const ProductOne = () => {
  const {
    t
  } = useTranslation();
  return <div className="grid gap-4 text-center" data-sentry-component="ProductOne" data-sentry-source-file="LandingIPhone16.tsx">
      <span className="text-lg text-red-600">ใหม่</span>
      <span className="text-4xl font-bold">iPhone 16</span>
      <span className="text-3xl">เร็วสุดแรง ฉลาดสุดล้ำ</span>
      <span>
        สั่งจองล่วงหน้า 13 ก.ย. 67 เวลา 19.00 น. วางจำหน่าย 20 ก.ย. 67{" "}
      </span>
      <span className="text-xl font-bold">เริ่มต้น ฿29,900</span>
      <Image alt="iPhone 16" className="mx-auto" height={240} src={"/img/products/iPhone_16.png"} width={800} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
      <div className="flex justify-center gap-2">
        <Link href={"/product/iphone-16"} data-sentry-element="Link" data-sentry-source-file="LandingIPhone16.tsx">
          <Button data-sentry-element="Button" data-sentry-source-file="LandingIPhone16.tsx">
            {t("seeMoreButtonText")} <RightOutlined data-sentry-element="RightOutlined" data-sentry-source-file="LandingIPhone16.tsx" />
          </Button>
        </Link>
        <NotifyButton data-sentry-element="NotifyButton" data-sentry-source-file="LandingIPhone16.tsx" />
      </div>
    </div>;
};
const ProductTwo = () => {
  const {
    t
  } = useTranslation();
  return <div className="grid gap-4 text-center" data-sentry-component="ProductTwo" data-sentry-source-file="LandingIPhone16.tsx">
      <span className="text-lg text-red-600">ใหม่</span>
      <span className="text-4xl font-bold">iPhone 16 Pro</span>
      <span className="text-3xl">ทรงพลังอย่างล้ำลึก</span>
      <span>
        สั่งจองล่วงหน้า 13 ก.ย. 67 เวลา 19.00 น. วางจำหน่าย 20 ก.ย. 67{" "}
      </span>
      <span className="text-xl font-bold">เริ่มต้น ฿39,900</span>
      <Image alt="iPhone 16 Pro" className="mx-auto" height={240} src={"/img/products/iPhone_16_Pro.png"} width={800} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
      <div className="flex justify-center gap-2">
        <Link href={"/product/iphone-16-pro"} data-sentry-element="Link" data-sentry-source-file="LandingIPhone16.tsx">
          <Button data-sentry-element="Button" data-sentry-source-file="LandingIPhone16.tsx">
            {t("seeMoreButtonText")} <RightOutlined data-sentry-element="RightOutlined" data-sentry-source-file="LandingIPhone16.tsx" />
          </Button>
        </Link>
        <NotifyButton data-sentry-element="NotifyButton" data-sentry-source-file="LandingIPhone16.tsx" />
      </div>
    </div>;
};
export default LandingIPhone16;