import useConfigurationStore from "@/lib/configuration/hooks/useConfigurationStore";
import { Button } from "antd";
import Link from "next/link";
import React from "react";
import { useTranslation } from "react-i18next";
export function NotifyButton() {
  const {
    t
  } = useTranslation();
  const {
    isCampaignActive
  } = useConfigurationStore();
  if (isCampaignActive) {
    <Link href="/iphone" target="_blank">
      <Button type="primary">{t("preOrderButtonText")}</Button>
    </Link>;
  }
  return <Link href="https://www.uficon.com/pre-order-notify-me" target="_blank" data-sentry-element="Link" data-sentry-component="NotifyButton" data-sentry-source-file="NotifyMeButton.tsx">
      <Button type="primary" data-sentry-element="Button" data-sentry-source-file="NotifyMeButton.tsx">{t("notifyButtonText")}</Button>
    </Link>;
}
export default NotifyButton;