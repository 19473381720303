import { MetaData } from "@/__generated__/graphql";
import { AxiosError } from "axios";
import { type ClassValue, clsx } from "clsx";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";

/**
 * Combines multiple class names into a single string.
 *
 * @param inputs - The class names to be combined.
 * @returns The combined class names as a string.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Handles the Axios error and displays an error toast message.
 * @param error - The Axios error object.
 */
export function handleAxiosError(error: AxiosError<any, any> | null) {
  const errorMessage =
    error?.response?.data?.message ||
    `An unexpected error happened: ${clearHtmlTags(error?.message as string)}`;

  toast.error(errorMessage);
}

/**
 * Removes HTML anchor tags from a given string.
 * @param html - The HTML string to remove anchor tags from.
 * @returns The modified string with anchor tags removed.
 */
export function clearHtmlTags(html: string): string {
  return html?.replace(/<a\b[^>]*>(.*?)<\/a>/i, "");
}

/**
 * Pauses the execution for a specified number of milliseconds.
 * @param ms - The number of milliseconds to sleep.
 * @returns A Promise that resolves after the specified time has elapsed.
 */
export async function sleep(ms: number) {
  await new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Checks if a campaign is currently active based on the campaign start and end dates.
 * @param campaignStartDate - The start date of the campaign.
 * @param campaignEndDate - The end date of the campaign.
 * @returns A boolean indicating whether the campaign is active or not.
 */
export const checkCampaignIsActive = ({
  campaignEndDate,
  campaignStartDate,
}: {
  campaignEndDate: null | string;
  campaignStartDate: null | string;
}) => {
  const now = dayjs();
  return (
    now.isAfter(dayjs(campaignStartDate)) &&
    now.isBefore(dayjs(campaignEndDate))
  );
};

/**
 * Finds the value of a specific key in an array of MetaData objects.
 * @param metaData - The array of MetaData objects to search in.
 * @param key - The key to search for.
 * @returns The value associated with the specified key, or undefined if not found.
 */
export function findMetaData(metaData: Array<MetaData>, key: string) {
  return metaData?.find((meta) => meta?.key === key)?.value;
}
